import * as Updates from 'expo-updates';
import React, { useContext, useEffect, useState } from 'react';

import Loading from '../components/Loading';
import UpdateModal from '../components/UpdateModal';
import WebViewScreen from '../components/WebViewScreen';
import { StateContext } from '../utils/StateProvider';

export default function NativeApp() {
  const { loading, setLoading } = useContext(StateContext);
  const [isUpdateModal, setIsUpdateModal] = useState(false);

  const checkOTAUpdate = async () => {
    try {
      // 新バージョンチェック
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        setLoading(true);
        await Updates.fetchUpdateAsync();
        setLoading(false);
        setIsUpdateModal(true);
      }
    } catch {}
  };
  const init = async () => {
    await checkOTAUpdate();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <WebViewScreen />
      <Loading isVisible={loading} />
      <UpdateModal isVisible={isUpdateModal} update={() => Updates.reloadAsync()} />
    </>
  );
}
