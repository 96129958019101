import AsyncStorage from '@react-native-async-storage/async-storage';
import { Alert } from 'react-native';
import * as Sentry from 'sentry-expo';

export type StorageKey =
  | 'DEVICEAUTH'
  | 'PUSH_DEVICE_TOKEN_SAVED'
  | 'PAYMENT_AUTH'
  | 'LAUNCHED'
  | 'IS_AGREE';

export const save = async <T>(key: StorageKey, value: T) => {
  try {
    await AsyncStorage.setItem(key, String(value));
  } catch (error) {
    Sentry.Native.captureException(error);
    Alert.alert('エラー', '予期せぬエラーが発生しました');
  }
};

export const load = async (key: StorageKey) => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (['PUSH_DEVICE_TOKEN_SAVED', 'PAYMENT_AUTH', 'LAUNCHED', 'IS_AGREE'].includes(key)) {
      return value === 'true';
    }
    // アプリ起動時の認証
    if (key === 'DEVICEAUTH') {
      if (value === null) {
        return false;
      }
      // falseとnull以外の場合はtrueを返す
      return value !== 'false';
    }
    return value;
  } catch (error) {
    Sentry.Native.captureException(error);
    Alert.alert('エラー', '予期せぬエラーが発生しました');
  }
};

export const clean = () => {
  return AsyncStorage.multiRemove([
    'DEVICEAUTH',
    'PAYMENT_AUTH',
    'PUSH_DEVICE_TOKEN_SAVED',
    'LAUNCHED',
    'IS_AGREE',
  ]);
};
