import {
  PASSWORD_CHANGE_SCREEN_SCRIPT,
  PASSWORD_RESET_SCREEN_SCRIPT,
  QRCODE_READ_SCREEN_SCRIPT,
  SHOP_LOGIN_SCREEN_SCRIPT,
} from './script';
import {
  LOGIN_SCREEN_ROUTE,
  PASSWORD_CHANGE_SCREEN_ROUTE,
  PASSWORD_RESET_SCREEN_ROUTE,
  QRCODE_READ_SCREEN_ROUTE,
} from '../../const/route';

const getInjectScript = (path: string) => {
  if (path.includes(LOGIN_SCREEN_ROUTE)) {
    return SHOP_LOGIN_SCREEN_SCRIPT;
  } else if (path.includes(PASSWORD_RESET_SCREEN_ROUTE)) {
    return PASSWORD_RESET_SCREEN_SCRIPT;
  } else if (path.includes(PASSWORD_CHANGE_SCREEN_ROUTE)) {
    return PASSWORD_CHANGE_SCREEN_SCRIPT;
  } else if (path.includes(QRCODE_READ_SCREEN_ROUTE)) {
    return QRCODE_READ_SCREEN_SCRIPT;
  }

  return null;
};

export { getInjectScript };
