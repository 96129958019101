const stagingOrganizations = [
  { name: 'ネクスペイ', value: 'fukuoka-staging' },
  { name: 'いいづかPAY', value: 'iizuka-staging' },
  { name: 'じょうづるさんPay', value: 'hitachiota-staging' },
  { name: 'むなかたPay', value: 'munakata-staging' },
  { name: 'AraoPAY', value: 'arao-staging' },
  { name: 'ちくちゃんペイ', value: 'chikuzen-staging' },
  { name: 'みやまスマイルペイ', value: 'miyama-staging' },
  { name: 'うきは市スマホ買い物券', value: 'ukiha-staging' },
  { name: '柳川藩Pay', value: 'yanagawa-staging' },
  { name: 'ふくつペイ', value: 'fukutsu-staging' },
  { name: 'いなＰＡＹ', value: 'inazawa-staging' },
  { name: 'いみず応援券', value: 'imizu-staging' },
  { name: '須恵町生活支援電子商品券', value: 'machinowa-sue-staging' },
  { name: 'Anshin Coin', value: 'anshincoin-staging' },
  { name: 'てげうまPay', value: 'miyazaki-staging' },
  { name: 'おおのジョーpay', value: 'oonojo-staging' },
  { name: '札幌生活応援プレミアム商品券', value: 'sapporo-staging' },
  { name: 'すえPAY', value: 'sue-staging' },
  { name: 'つるおかペイ', value: 'tsuruoka-staging' },
  { name: 'くりみんpay', value: 'sasaguri-staging' },
  { name: 'CORDA環境ペイ', value: 'corda' },
  { name: 'かすやよかっPay', value: 'kasuya-staging' },
  { name: 'なかまPay', value: 'nakama-staging' },
  { name: 'わかまつＰＡＹ', value: 'machinowa-wakamatsu-staging' },
];

const productionOrganizations = [
  { name: 'ネクスペイ', value: 'fukuoka' },
  { name: 'いいづかPAY', value: 'iizuka' },
  { name: 'じょうづるさんPay', value: 'hitachiota' },
  { name: 'むなかたPay', value: 'munakata' },
  { name: 'AraoPAY', value: 'arao' },
  { name: 'ちくちゃんペイ', value: 'chikuzen' },
  { name: 'みやまスマイルペイ', value: 'miyama' },
  { name: 'うきは市スマホ買い物券', value: 'ukiha' },
  { name: 'ふくつペイ', value: 'fukutsu' },
  { name: 'いなＰＡＹ', value: 'inazawa' },
  { name: 'いみず応援券', value: 'imizu' },
  { name: '須恵町生活支援電子商品券', value: 'machinowa-sue' },
  { name: 'Anshin Coin', value: 'anshincoin' },
  { name: 'すえPAY', value: 'sue' },
  { name: 'くりみんpay', value: 'sasaguri' },
  { name: 'かすやよかっPay', value: 'kasuya' },
  { name: 'なかまPay', value: 'nakama' },
  { name: 'わかまつＰＡＹ', value: 'machinowa-wakamatsu' },
];

export { stagingOrganizations, productionOrganizations };
