import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';

const registerForPushNotificationsAsync = async (): Promise<string | boolean> => {
  let token;

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#4FC3CF',
      sound: 'default',
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      return false; // Push通知無効
    }
    const { data } = await Notifications.getDevicePushTokenAsync();
    token = data;
  } else {
    return false; // このアプリではありえないケースだけど、iOS/Android以外の端末
  }

  return token;
};

export { registerForPushNotificationsAsync };
