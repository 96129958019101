import React from 'react';
import { StyleSheet, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { WebViewNavigation } from 'react-native-webview';

type IProps = {
  visible: boolean;
  goBack: () => void;
  goForward: () => void;
  webViewState: WebViewNavigation | null;
};
function WebViewNavBar(props: IProps) {
  const { visible, goBack, goForward, webViewState } = props;
  return (
    <View>
      {visible && (
        <View style={styles.buttonWrapper}>
          <Icon
            name="chevron-left"
            size={30}
            onPress={goBack}
            color={webViewState?.canGoBack ? '#000000' : '#0000003D'}
          />
          <Icon
            name="chevron-right"
            size={30}
            onPress={goForward}
            color={webViewState?.canGoForward ? '#000000' : '#0000003D'}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  buttonWrapper: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 5,
  },
});

export default WebViewNavBar;
