import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

type IProps = {
  isVisible?: boolean;
};

const Loading = (props: IProps) => {
  const { isVisible = false } = props;
  return (
    <>
      {isVisible && (
        <View style={styles.container}>
          <ActivityIndicator size="large" color="#4FC3CF" />
        </View>
      )}
    </>
  );
};

export default Loading;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000000',
    opacity: 0.5,
  },
});
