import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { BarCodeScanningResult, Camera } from 'expo-camera';
import * as Linking from 'expo-linking';
import React, { useState, useEffect, ReactNode } from 'react';
import {
  StyleSheet,
  ViewStyle,
  View,
  TextStyle,
  Platform,
  Dimensions,
  Text,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';

import { scannerStatus } from '../utils/scannerStatus';

type QRCodeReaderProps = {
  isVisible: boolean;
  onBarCodeScanned?: ((scanningResult: BarCodeScanningResult) => void) | undefined;
  onClose: () => void;
  title: string;
  instruction: string;
  children?: ReactNode;
};
interface IStyle {
  viewPort: ViewStyle;
  scanner: ViewStyle;
  header: ViewStyle;
  headerSafeArea: ViewStyle;
  headerTitle: TextStyle;
  headerLeftIcon: TextStyle;
  headerRightIcon: TextStyle;
  instructionBox: ViewStyle;
  instructionText: TextStyle;
  childrenArea: ViewStyle;
  scanAreaContainer: ViewStyle;
  topOverlay: ViewStyle;
  bottomOverlay: ViewStyle;
  leftAndRightOverlay: ViewStyle;
  scanArea: ViewStyle;
  qrIcon: TextStyle;
  body: ViewStyle;
  noPermissionView: ViewStyle;
  buttonContainer: ViewStyle;
  buttonText: TextStyle;
}

// デフォルトのアスペクト比
const DEFAULT_ASPECT_RATIO = '16:9';

function convertRatioFromString(ratioString: string): number {
  const ratioParts = ratioString.split(':');
  if (ratioParts.length !== 2) {
    throw new Error("Invalid ratio format. Expected format is 'X:Y'.");
  }
  const numerator = Number(ratioParts[0]);
  const denominator = Number(ratioParts[1]);
  if (isNaN(numerator) || isNaN(denominator)) {
    throw new Error("Invalid ratio format. Expected format is 'X:Y' where X and Y are numbers.");
  }
  return numerator / denominator;
}

/**
 * QRコードリーダー
 */

function QRCodeReader(props: QRCodeReaderProps) {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [camera, setCamera] = useState<Camera | null>(null);
  const [ratio, setRatio] = useState(DEFAULT_ASPECT_RATIO);
  const [isRatioSet, setIsRatioSet] = useState(false);
  const { height, width } = Dimensions.get('window');
  const screenRatio = height / width;

  const [screenData, setScreenData] = useState({
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  });

  const onChange = (newDimensions) => {
    setScreenData({
      width: newDimensions.window.width,
      height: newDimensions.window.height,
    });
  };

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
  }, []);

  useEffect(() => {
    (async () => {
      setHasPermission(await scannerStatus());
    })();
  }, []);

  // 許可を取る際の画面
  if (hasPermission === null) {
    return <View />;
  }

  // 未許可時の処理
  if (!hasPermission) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: '#FFFFFF',
          position: 'absolute',
          height: '100%',
        }}>
        <View style={styles.header}>
          <SafeAreaView style={styles.headerSafeArea}>
            <AntDesign
              onPress={props.onClose}
              name="close"
              size={25}
              style={styles.headerLeftIcon}
            />
            <Text style={styles.headerTitle}>{props.title}</Text>
          </SafeAreaView>
        </View>
        <View
          style={{
            flex: 1,
            backgroundColor: '#FFFFFF',
          }}>
          <View style={styles.noPermissionView}>
            <Text style={{ textAlign: 'center', fontSize: 14 }}>
              カメラへのアクセス権がありません。設定を開き、このアプリからのカメラアクセスを許可してください。
            </Text>
            <TouchableOpacity
              style={{ marginTop: 20 }}
              onPress={() => {
                Linking.openSettings();
                props.onClose();
              }}>
              <View style={styles.buttonContainer}>
                <Text style={styles.buttonText}>設定を開く</Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={styles.childrenArea}>{props.children}</View>
        </View>
      </View>
    );
  }

  const prepareRatio = async () => {
    let desiredRatio = DEFAULT_ASPECT_RATIO;
    // Androidではアスペクト比がおかしくなるので、画面サイズに応じて設定する
    if (Platform.OS === 'android') {
      const ratios = await camera?.getSupportedRatiosAsync();
      if (!ratios) return;

      // 画面の縦と横の比率に一番近いアスペクト比を決める
      const distances = {};
      const realRatios = {};
      let minDistance: string | null = null;
      for (const ratio of ratios) {
        const parts = ratio.split(':');
        const realRatio = parseInt(parts[0], 10) / parseInt(parts[1], 10);
        realRatios[ratio] = realRatio;
        // 端末サイズの比率からサポートしている比率を引いて距離を算出
        const distance = screenRatio - realRatio;
        distances[ratio] = realRatio;
        // 距離が一番小さいサポートしている比率を選ぶ
        if (minDistance == null) {
          minDistance = ratio;
        } else {
          if (distance >= 0 && distance < distances[minDistance]) {
            minDistance = ratio;
          }
        }
      }
      desiredRatio = minDistance ?? desiredRatio;
      setRatio(desiredRatio);
      setIsRatioSet(true);
    }
  };
  const { width: screenWidth, height: screenHeight } = Dimensions.get('window');

  const setCameraReady = async () => {
    if (!isRatioSet) {
      await prepareRatio();
    }
  };

  const cameraViewHeight =
    Platform.OS === 'ios' ? screenHeight : screenWidth * convertRatioFromString(ratio);
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#000000',
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}>
      <View
        style={{
          width: screenWidth,
          height: cameraViewHeight,
          overflow: 'hidden',
        }}>
        <Camera
          onBarCodeScanned={props.onBarCodeScanned}
          ratio={ratio}
          onCameraReady={setCameraReady}
          barCodeScannerSettings={{
            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
          }}
          ref={(ref) => {
            setCamera(ref);
          }}
          style={[styles.scanner]}>
          <View style={styles.viewPort}>
            <View style={styles.header}>
              <SafeAreaView style={styles.headerSafeArea}>
                <AntDesign
                  onPress={props.onClose}
                  name="close"
                  size={25}
                  style={styles.headerLeftIcon}
                />
                <View style={{ alignItems: 'center' }}>
                  <Text style={styles.headerTitle}>{props.title}</Text>
                </View>
                <View style={styles.headerLeftIcon} />
              </SafeAreaView>
            </View>
            <View style={{ ...styles.topOverlay, height: screenData.height * 0.2 }}>
              <View
                style={{
                  ...styles.instructionBox,
                  width: screenData.width * 0.75,
                  marginTop: screenData.height * 0.015,
                  padding: screenData.height * 0.015,
                }}>
                <Text style={styles.instructionText}>{props.instruction}</Text>
              </View>
            </View>
            <View style={styles.scanAreaContainer}>
              <View
                style={{
                  ...styles.leftAndRightOverlay,
                  width:
                    screenData.width < screenData.height
                      ? screenData.width * 0.25
                      : (screenData.width - screenData.height * 0.5) / 2,
                }}
              />
              <View
                style={{
                  ...styles.scanArea,
                  height:
                    screenData.width < screenData.height
                      ? screenData.width * 0.5
                      : screenData.height * 0.5,
                  width:
                    screenData.width < screenData.height
                      ? screenData.width * 0.5
                      : screenData.height * 0.5,
                }}>
                <FontAwesome name="qrcode" size={120} style={styles.qrIcon} />
              </View>
              <View
                style={{
                  ...styles.leftAndRightOverlay,
                  width:
                    screenData.width < screenData.height
                      ? screenData.width * 0.25
                      : (screenData.width - screenData.height * 0.5) / 2,
                }}
              />
            </View>
            <View style={styles.bottomOverlay} />
          </View>
        </Camera>
      </View>
      <View style={styles.childrenArea}>{props.children}</View>
    </View>
  );
}

export default QRCodeReader;

const styles = StyleSheet.create<IStyle>({
  viewPort: {
    flex: 1,
    flexDirection: 'column',
  },
  scanner: {
    backgroundColor: 'rgba(17,17,17,0.6)',
    flex: 1,
  },
  header: {
    backgroundColor: 'rgba(17,17,17,0.8)',
    borderBottomColor: '#000',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
  },
  headerSafeArea: {
    width: '100%',
    flexDirection: 'row',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 18,
    flex: 3,
  },
  headerLeftIcon: {
    color: '#FFFFFF',
    flex: 1,
  },
  headerRightIcon: {
    color: '#FFFFFF',
  },
  instructionBox: {
    borderRadius: 5,
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#20a8d8',
  },
  instructionText: {
    fontSize: 18,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  scanAreaContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  topOverlay: {
    backgroundColor: 'rgba(17,17,17,0.6)',
    alignItems: 'center',
  },
  bottomOverlay: {
    flex: 1,
    backgroundColor: 'rgba(17,17,17,0.6)',
  },
  leftAndRightOverlay: {
    backgroundColor: 'rgba(17,17,17,0.6)',
  },
  childrenArea: {
    backgroundColor: '#000000',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  scanArea: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  qrIcon: {
    color: '#FFFFFF',
  },
  body: {
    flex: Platform.OS === 'android' ? 2 : 4,
  },
  noPermissionView: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    marginTop: 60,
  },
  buttonContainer: {
    backgroundColor: '#1C8F9C',
    borderRadius: 4,
    padding: 10,
    width: 200,
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 18,
    color: '#FFFFFF',
  },
});
