import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';

import { StateContext } from '../utils/StateProvider';

function NetworkError() {
  const { isConnect } = useContext(StateContext);

  return <>{!isConnect && <View style={styles.container} />}</>;
}

export default NetworkError;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '150%',
    zIndex: 100,
  },
});
