import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';

const getRememberLoginDataKey = (organization: string) => {
  return `REMEMBER_LOGIN_DATA_${organization}`;
};

export type LoginData = {
  password: string;
  email: string;
};

const getLoginDataFromStorage = async (organization: string) => {
  const data = await SecureStore.getItemAsync(getRememberLoginDataKey(organization));
  if (data) {
    const parsedData = JSON.parse(data);
    return parsedData as LoginData;
  }
  // SecureStoreでデータが取れなかった場合、AsyncStorageでもトライ（SecureStoreでデータが消えている可能性があるため）
  const asyncData = await AsyncStorage.getItem(getRememberLoginDataKey(organization));
  if (!asyncData) return;
  // AsyncStorageにはデータがあって、SecureStoreにはなかったので再保存する
  await SecureStore.setItemAsync(getRememberLoginDataKey(organization), asyncData);
  const parsedData = JSON.parse(asyncData);
  return parsedData as LoginData;
};

const setLoginDataToStorage = async (params: { data: LoginData; organization: string }) => {
  const { data, organization } = params;
  const loginData = JSON.stringify({
    password: data.password,
    email: data.email,
  });
  await SecureStore.setItemAsync(getRememberLoginDataKey(organization), loginData);
  await AsyncStorage.setItem(getRememberLoginDataKey(organization), loginData); // SecureStoreが消える可能性があるので予備で保存
};

const removeLoginDataFromStorage = async (organization: string) => {
  await Promise.all([
    SecureStore.deleteItemAsync(getRememberLoginDataKey(organization)),
    AsyncStorage.removeItem(getRememberLoginDataKey(organization)),
  ]);
};

// 保存ID/PASSを自動入力
const getLoginFormInputCode = (data: LoginData) => {
  return `
setTimeout(() => {
  document.querySelector('[name="email"]').value = '${data.email}';
  document.querySelector('[name="password"]').value = '${data.password}'; 
}, 100);
true;
`;
};

export {
  getLoginDataFromStorage,
  setLoginDataToStorage,
  removeLoginDataFromStorage,
  getLoginFormInputCode,
};
