import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

type ButtonType = 'solid' | 'outline';

type IProps = {
  name: string;
  type?: ButtonType;
  color: string;
  onPress: () => void;
  disabled?: boolean;
};
const Button = (props: IProps) => {
  const { name, type = 'solid', color, onPress, disabled = false } = props;
  const borderColor =
    type === 'outline'
      ? {
          borderColor: color,
          borderWidth: 2,
        }
      : {};
  const backgroundColor = disabled
    ? { backgroundColor: '#CCCCCC' }
    : { backgroundColor: type === 'solid' ? color : '#FFFFFF' };
  const containerStyle = { ...styles.container, ...backgroundColor, ...borderColor };

  const textColor = type === 'solid' ? '#FFFFFF' : color;
  const textStyle = { ...styles.text, color: textColor };

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <View style={containerStyle}>
        <Text style={textStyle}>{name}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default Button;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});
