const postDeviceTokenScript = (token: string, osType: number) => {
  return `
(function () {
  const bodyData = {
    device_token: "${token}",
    os_type: "${osType}",
  };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
    body: JSON.stringify(bodyData),
  };
  fetch("/shop/deviceToken/register", options)
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.error(error));
})();
`;
};

export { postDeviceTokenScript };
