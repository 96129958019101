import React from 'react';
import { StyleSheet, View, Text, ScrollView, TouchableWithoutFeedback } from 'react-native';
import Modal from 'react-native-modal';

import Button from './Button';

export default function LoginModal(props: {
  isVisible: boolean;
  onBackdropPressed: () => void;
  login: (rememberMe: boolean) => void;
  saveButtonText?: string;
  noSaveButtonText?: string;
}) {
  const {
    login,
    isVisible,
    onBackdropPressed,
    saveButtonText = '保存してログイン',
    noSaveButtonText = '保存しないでログイン',
  } = props;
  return (
    <Modal
      isVisible={isVisible}
      customBackdrop={
        <TouchableWithoutFeedback onPress={onBackdropPressed}>
          <View style={modalStyles.modalBackdrop} />
        </TouchableWithoutFeedback>
      }>
      <View style={modalStyles.container}>
        <View style={modalStyles.modal}>
          <Text style={modalStyles.modalTitle}>ログイン情報の保存</Text>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Text style={modalStyles.modalText}>{`
メールアドレスとパスワードを端末に保存しますか？
端末に保存すると次回ログイン時に入力を省略できます。
`}</Text>
          </ScrollView>
          <View style={modalStyles.buttonBlock}>
            <Button
              name={saveButtonText}
              type="solid"
              color="#1C8F9C"
              onPress={() => login(true)}
            />
            <View style={modalStyles.spacer} />
            <Button
              name={noSaveButtonText}
              type="outline"
              color="#1C8F9C"
              onPress={() => login(false)}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
}

const modalStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    maxWidth: 500,
    height: '42%',
    maxHeight: 300,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
  },
  spacer: {
    height: 10,
  },
  borderedButton: {
    borderColor: '#1C8F9C',
    width: '100%',
  },
  borderedButtonTitle: {
    color: '#1C8F9C',
  },
  button: {
    width: '100%',
  },
  modalBackdrop: {
    flex: 1,
    backgroundColor: '#000',
  },
  buttonBlock: {
    marginVertical: 10,
  },
  modalTitle: {
    textAlign: 'center',
    marginTop: 10,
    color: '#111111',
    fontSize: 22,
    fontWeight: 'bold',
  },
  modalText: {
    padding: 10,
  },
});
