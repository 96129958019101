import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

type StateContextType = {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isConnect: boolean;
  setIsConnect: Dispatch<SetStateAction<boolean>>;
};

export const StateContext = createContext<StateContextType>({
  loading: false,
  setLoading: () => {},
  isConnect: true,
  setIsConnect: () => {},
});

type StateProviderProps = {
  children: ReactNode;
};
const StateProvider = (props: StateProviderProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isConnect, setIsConnect] = useState<boolean>(true);

  const value: StateContextType = {
    loading,
    setLoading,
    isConnect,
    setIsConnect,
  };

  return <StateContext.Provider value={value}>{props.children}</StateContext.Provider>;
};

export default StateProvider;
