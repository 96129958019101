import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import Button from './Button';

type IProps = {
  isVisible: boolean;
  update: () => void;
};
function UpdateModal(props: IProps) {
  const { isVisible, update } = props;
  return (
    <Modal isVisible={isVisible}>
      <View style={styles.modal}>
        <View style={styles.modalContent}>
          <Text style={styles.title}>バージョン更新</Text>
          <Text style={styles.description}>新しいバージョンが見つかりました。</Text>
          <Text style={styles.description}>アプリを再起動します。</Text>
          <View style={styles.button}>
            <Button name="再起動" type="solid" color="#1C8F9C" onPress={update} />
          </View>
        </View>
      </View>
    </Modal>
  );
}

export default UpdateModal;

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    width: 340,
    padding: 16,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 32,
  },
  description: {
    textAlign: 'center',
    fontSize: 18,
  },
  button: {
    marginTop: 32,
  },
});
