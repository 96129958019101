import { Camera } from 'expo-camera';
import { Platform } from 'react-native';

import { load, save } from './storage';

// Androidの場合、カメラに権限を当ててくださいの表示がバックグラウンド判定になり
// デバイス認証が発動するので、許諾を求める前にoffにして再度状態を戻す
async function scannerStatus() {
  const deviceLocked = await load('DEVICEAUTH');
  if (Platform.OS === 'android') await save('DEVICEAUTH', false);
  const { status } = await Camera.requestCameraPermissionsAsync();
  if (Platform.OS === 'android') await save('DEVICEAUTH', deviceLocked);
  return status === 'granted';
}

export { scannerStatus };
